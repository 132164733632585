$( document ).ready(function() {
  var requiredPath = "/products/14460";
  var pagepath = "/nude-collection-mpp";
  var pathName = window.document.location.pathname;
  if(pathName == requiredPath) {
    $( ".product__button.button.button--secondary.product__button--add-to-bag.js-add-to-cart.js-product-cta" ).each( function( ){
    $(this)[0].text = 'สั่งซื้อเลย';
    });
  }
  if(pathName == pagepath) {
    $( ".link.link--inverted.product-thumb__cta" ).each( function( ){
    $(this)[0].text = 'สั่งซื้อเลย';
    });
  }
});

